import React, {useEffect, useState} from "react";
import {debounce} from "lodash";
import {useHistory} from "react-router";
import {getUserAccounts, doRegister, setPassword, removeAccount} from "../../Api";
import {useToast} from "../../Components/Toast/ToastProvider";
import Pagination from "../../Components/Pagination/Pagination";
import "./userpage.css";
import RoleSelect, {defaultRole} from "../../Components/RoleSelect/RoleSelect.js";

function UsersPage() {
    const toast = useToast();
    const history = useHistory();
    const [accounts, setAccounts] = useState({
        TotalPages: 1,
        PageNumber: 1,
        Elements: []
    });
    const [formData, setFormData] = useState({
        username: "",
        kundennummer: "",
        password: "",
        role: defaultRole
    });
    const [totalPages, setTotalPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [newPassword, setNewPassword] = useState(null);
    const [fetchOptions, setFetchOptions] = useState({
        pageNumber: 1,
        pageSize: 10,
        Search: "",
        OrderBy: "Role",
        OrderDirection: "ASC",
    });
    const [forceReload, setForceReload] = useState(true);

    useEffect(() => {
        fetchAccountsData();
    }, [fetchOptions, forceReload]);
    useEffect(() => {
        const {TotalPages, PageNumber} = accounts;
        setTotalPages(TotalPages);
        setCurrentPage(PageNumber);
    }, [accounts])


    const searchdebounced = debounce((value) => {
        setFetchOptions({...fetchOptions, Search: value, pageNumber: 1});
    }, 500)

    function handleSearch({target: {value}}) {
        setSearch(value);
        searchdebounced(value);
    }

    function setPage(pageNumber) {
        setFetchOptions({...fetchOptions, pageNumber});
    }

    async function fetchAccountsData() {
        const noCache = true;
        const userAccounts = await getUserAccounts({...fetchOptions, noCache});
        setAccounts(userAccounts);
        setForceReload(false);
        setIsLoading(false);
    }

    async function addNewUser() {
        const {role, ...user} = formData;
        const res = await doRegister(user, role);
        if (res) {
            toast.push("Nutzer wurde erfolgreich hinzugefügt", "Success", 4000);
            setForceReload(true);
        }
    }

    function handleHeaderClick(column) {
        const sort = {OrderBy: column, OrderDirection: "ASC"};
        if (fetchOptions.OrderBy == sort.OrderBy) {
            sort.OrderDirection = fetchOptions.OrderDirection == "ASC" ? "DESC" : "ASC";
        }
        setFetchOptions({...fetchOptions, ...sort});
    }

    async function changePassword(userId) {
        if (newPassword) {
            const res = await setPassword(userId, newPassword);
            if (res) {
                const inputs = document.querySelectorAll("input");
                inputs.forEach((i) => (i.value = ""));
                toast.push("Passwort wurde erfolgreich geändert", "Success", 4000);
            } else {
                toast.push("Passwort konnte nicht  geändert werden", "Error", 4000);
            }
        }
    }

    async function deleteUser(userId) {
        const res = await removeAccount(userId);
        if (res) {
            setForceReload(true);
            toast.push("Nutzer wurde erfolgreich gelöscht", "Success", 4000);
        } else {
            toast.push("Nutzer konnte nicht  gelöscht werden", "Error", 4000);
        }
    }

    return (
        <div className="flex flex-col my-12">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block py-2 align-middle sm:px-6 lg:px-8">
                    <h3 className="px-4 my-8 text-3xl font-medium text-gray-700">
                        Nutzer
                    </h3>
                    <div className="my-4 overflow-hidden border-b border-gray-400 shadow sm:rounded-lg">

                        <input
                            type="text"
                            placeholder="Suchen"
                            onChange={handleSearch}
                            value={search}
                            className="relative block w-48 px-5 py-2 mx-3 mt-3 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                        />
                        <table className="w-full min-w-full table-auto divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                >
                                    <button onClick={() => handleHeaderClick('Benutzername')}>Benutzername</button>
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                >
                                    <button onClick={() => handleHeaderClick('Kundennummer')}>Kundennummer</button>
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                >
                                    Kennwort
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                ><span className="sr-only">Edit</span></th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                ><span className="sr-only">Löschen</span></th>
                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {isLoading ?
                                <tr>
                                    <td colspan="5"><span className="h-full">Laden...</span></td>
                                </tr>
                                : accounts?.Elements?.map((user, i) => (
                                    <tr className="hover:bg-gray-100 " key={i}>
                                        <td label="Benutzername" className="px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center">
                                                <div className="ml-4">
                                                    <div className="text-sm font-medium text-gray-900">
                                                        {user.Benutzername}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td label="Kundennummer" className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">
                                                {user.Kundennummer}
                                            </div>
                                        </td>
                                        <td label="Passwort" className="px-6 py-4 whitespace-nowrap flex">
                                            <input
                                                onFocus={() => setNewPassword("")}
                                                onChange={({target: {value}}) =>
                                                    setNewPassword(value)
                                                }
                                                autocomplete="new-password"
                                                className="relative block w-full px-3 py-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-l-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                                type="password"
                                            />
                                            <button
                                                className="px-6 text-sm text-gray-500 whitespace-nowrap border border-gray-300 border-l-0 rounded-none appearance-none rounded-r-md"
                                                onClick={() => changePassword(user.UserID)}>
                                                aktualisieren
                                            </button>
                                        </td>
                                        <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                                            <button className="text-indigo-600 hover:text-indigo-900"
                                                    onClick={() => history.push({
                                                        pathname: `/alteruser/${user.UserID}`,
                                                        user
                                                    })}>
                                                bearbeiten
                                            </button>
                                        </td>

                                        <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                                            <button className="text-indigo-600 hover:text-indigo-900"
                                                    onClick={() => deleteUser(user.UserID)}>
                                                löschen
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>

                        <div className="bg-white p-9">
                            <Pagination totalPages={totalPages} currentPage={currentPage}
                                        handlePageChange={setPage}/>
                        </div>
                    </div>
                    <div className="px-4 py-8 mt-8 bg-white border-b border-gray-200 shadow sm:rounded-lg">
                        <label className="text-xl">Nutzer anlegen</label>
                        <div
                            className="relative flex h-24 px-8 py-6 my-2 bg-white place-content-around sm:rounded-lg">
                            <div className="mr-4">
                                <input
                                    name="username"
                                    type="text"
                                    onChange={({target: {value, name}}) =>
                                        setFormData({...formData, [name]: value})
                                    }
                                    className="w-full p-3 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-b appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                    placeholder="Nutzername"
                                />
                            </div>
                            <div className="mr-4">
                                <input
                                    name="kundennummer"
                                    onChange={({target: {value, name}}) =>
                                        setFormData({...formData, [name]: value})
                                    }
                                    type="text"
                                    className="w-full p-3 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-b appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                    placeholder="Kundennummer"
                                />
                            </div>
                            <div className="mr-4">
                                <input
                                    name="password"
                                    onChange={({target: {value, name}}) =>
                                        setFormData({...formData, [name]: value})
                                    }
                                    type="password"
                                    autocomplete="new-password"
                                    className="w-full p-3 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-b appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                    placeholder="Passwort"
                                />
                            </div>
                            <div className="mr-4">
                                <RoleSelect
                                    handleChange={(newValue) =>
                                        setFormData({...formData, role: newValue})
                                    }
                                    currentValue={formData.role}
                                    className="w-full p-3 text-gray-900 border border-gray-300 rounded-b appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                />
                            </div>
                            <div>
                                <div onClick={addNewUser}
                                     className="inline-flex items-center px-3 py-2 text-base font-medium text-white bg-blue-900 border border-transparent whitespace-nowrap rounded-md shadow-sm hover:bg-blue-600"
                                >
                                    <span>anlegen</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersPage;
