import React, {useEffect, useState} from "react";
import {getTimeRulesSettings} from "../../Api";
import {useToast} from "../../Components/Toast/ToastProvider";
import {setTimeRulesSettings} from "../../Api";
import {rule} from "postcss";

const typEnum = {
    0: "Relativ Zu ETA",
    1: "Stunde des Tages",
};

const invalidFields = {
    "Relativ Zu ETA": ["HourOfDay"],
    "Stunde des Tages": ["RelativeMinutesFrom", "RelativeMinutesTo"]
}


function SettingsPage() {
    const defaultrule = {
        Id: 0,
        Type: 1,
        RelativeMinutesFrom: null,
        RelativeMinutesTo: null,
        HourOfDay: null,
        MinutenVon: 0,
        MinutenBis: 0,
        Bemerkung: "",
    };
    const [timeRules, setTimeRules] = useState([]);
    const [formData, setFormData] = useState(defaultrule);
    const toast = useToast();
    const formIsAbsolute = () => formData.Type == 1;


    function Row({rule}) {
        function setBG(prop) {
            if (invalidFields[typEnum[rule.Type]].includes(prop)) {
                return "bg-gray-100";
            }
            return "";
        }

        return (
            rule && (
                <tr className="flex-grow my-12 rounded-lg shadow-md hover:bg-blue-50 hover:shadow-xl">
                    <td label="Typ" className="p-3 border border-grey-light">
                        {rule.Id}
                    </td>
                    <td label="Typ" className="p-3 border border-grey-light ">
                        {typEnum[rule.Type]}
                    </td>
                    <td label="Stundentag" className={`p-3  border border-grey-light ${setBG("HourOfDay")}`}>
                        {rule.HourOfDay}
                    </td>
                    <td label="Relative Minuten von"
                        className={`${setBG("RelativeMinutesFrom")} p-3  border border-grey-light`}>
                        {rule.RelativeMinutesFrom}
                    </td>

                    <td label="Relative Minuten bis"
                        className={`${setBG("RelativeMinutesTo")} p-3  border border-grey-light `}>
                        {rule.RelativeMinutesTo}
                    </td>
                    <td label="Minuten Von" className={`${setBG("MinutenVon")} p-3  border border-grey-light`}>
                        {rule.MinutenVon}
                    </td>
                    <td label="MinutenBis" className={`${setBG("MinutenBis")} p-3  border border-grey-light`}>
                        {rule.MinutenBis}
                    </td>
                    <td label="Bemerkung" className={`${setBG("Bemerkung")} p-3  border border-grey-light `}>
                        {rule.Bemerkung}
                    </td>
                    <td label="löschen" className="p-3  border border-grey-light">
                        <button className={`text-indigo-600 hover:text-indigo-900`}
                                onClick={() => deleteRule(rule)}>löschen
                        </button>
                    </td>
                </tr>
            )
        );
    }

    function handleFormChange({target: {id, value}}) {
        const newData = {[id]: value};
        if (id !== "Bemerkung") {
            newData[id] = Number(newData[id]);
        }
        if (id == "Type") {
            invalidFields[typEnum[newData[id]]].forEach(key => {
                newData[key] = null;
            });
        }
        setFormData({...formData, ...newData});
    }

    async function deleteRule(rule) {
        const i = timeRules.indexOf(rule);
        timeRules.splice(i, 1);
        updateTimeRules(timeRules);
    }


    async function handleSubmitRules(e) {
        e.preventDefault();
        timeRules.push(formData);
        updateTimeRules(timeRules);
    }

    async function updateTimeRules(newRules) {
        const res = await setTimeRulesSettings(newRules);
        if (res.ok) {
            const inputs = document.querySelectorAll("input");
            const textarea = document.querySelectorAll("textarea");
            console.log(inputs);
            inputs.forEach((i) => (i.value = ""));
            textarea.forEach((t) => (t.value = ""));
            toast.push("Regeln wurden erfolgreich gespeichert", "Success", 4000);
            setTimeRules(res.Rules);
            // fetchTimeRuleSettings();
        } else {
            toast.push("Regeln konnten nicht gespeichert werden", "Error", 4000);
        }
    }

    useEffect(() => {
        (async () => {
            await fetchTimeRuleSettings();
        })();
    }, []);

    console.log("TIME RULES", timeRules);

    async function fetchTimeRuleSettings() {
        const timeRules = await getTimeRulesSettings();
        if (timeRules.ok) {
            setFormData({...formData, Id: 0});
            setTimeRules(timeRules.Rules);
        }
    }

    return (
        <div className="flex flex-col my-12">
            <table
                className="flex flex-row overflow-hidden bg-white rounded-lg m:w-screen sm:bg-white sm:shadow-lg">
                <thead className="text-black">
                {timeRules &&
                    <tr
                        key={"header"}
                        className="flex flex-col mb-2 bg-teal-400 rounded-l-lg flex-nowrap wrap sm:table-row sm:rounded-none sm:mb-3"
                    >
                        <th className="p-3 text-left">ID</th>
                        <th className="p-3 text-left">Typ</th>
                        <th className="p-3 text-left">Stundentag</th>
                        <th className="p-3 text-left">Relative Minuten von</th>
                        <th className="p-3 text-left"> Relative Minuten bis</th>
                        <th className="p-3 text-left"> Minuten von</th>
                        <th className="p-3 text-left"> Minuten bis</th>
                        <th className="p-3 text-left"> Bemerkung</th>
                        <th className="p-3"></th>
                    </tr>
                }
                </thead>
                <tbody className="overflow-x-auto">
                {timeRules &&
                    timeRules.map((rule, key) => rule && <Row key={key} rule={rule}/>)}
                </tbody>
            </table>

            <form onSubmit={handleSubmitRules} className="w-full my-12">
                <div className="container flex items-center justify-center p-2 mx-auto">
                    <div className="p-6 bg-white border border-gray-300 rounded-lg shadow-lg grid grid-cols-1 gap-6">
                        <div className="flex flex-col md:flex-row">
                            <div
                                className={"p-2 border border-gray-200 rounded grid  gap-2 " + (formIsAbsolute() ? "grid-cols-2" : "grid-cols-3")}>
                                <div className="flex items-center ">
                                    <select
                                        id="Type"
                                        onChange={handleFormChange}
                                        className="p-2 border rounded w-full"
                                    > {
                                        Object.entries(typEnum).map(([key, label]) => {
                                            return (
                                                <option value={key} selected={key == formData.Type}>{label}</option>);
                                        })
                                    }

                                    </select>
                                </div>

                                {formIsAbsolute() && <div className="flex items-center p-2 border rounded">
                                    <input
                                        onChange={handleFormChange}
                                        id="HourOfDay"
                                        type="number"
                                        placeholder="Stundentag"
                                        className="w-32 text-gray-700 focus:outline-none"
                                    />
                                </div>}

                                {!formIsAbsolute() &&
                                    <div className="flex items-center p-2 border rounded ">
                                        <input
                                            onChange={handleFormChange}
                                            id="RelativeMinutesFrom"
                                            type="number"
                                            step="60"
                                            placeholder="Relative Minuten von"
                                            className="max-w-full text-gray-700 focus:outline-none"
                                        />
                                    </div>
                                }
                                {!formIsAbsolute() &&
                                    <div className="flex items-center p-2 border rounded ">
                                        <input
                                            onChange={handleFormChange}
                                            id="RelativeMinutesTo"
                                            step="60"
                                            type="number"
                                            placeholder="Relative Minuten bis"
                                            className="max-w-full text-gray-700 focus:outline-none"
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                            <div className="p-2 border border-gray-200 rounded grid grid-cols-2 gap-2">
                                <div className="flex items-center p-2 border rounded ">
                                    <input
                                        onChange={handleFormChange}
                                        id="MinutenVon"
                                        type="number"
                                        step="5"
                                        placeholder="Minuten von"
                                        className="max-w-full text-gray-700 focus:outline-none"
                                    />
                                </div>
                                <div className="flex items-center p-2 border rounded ">
                                    <input
                                        onChange={handleFormChange}
                                        id="MinutenBis"
                                        type="number"
                                        step="5"
                                        placeholder="Minuten bis"
                                        className="max-w-full text-gray-700 focus:outline-none"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center p-2 border rounded ">
              <textarea
                  onChange={handleFormChange}
                  id="Bemerkung"
                  placeholder="Bemerkung"
                  className="max-w-full text-gray-700 focus:outline-none"
              />
                        </div>
                        <div className="flex justify-center">
                            <button className="p-2 text-white bg-blue-600 border lg:w-1/4 rounded-md">
                                Abschicken
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SettingsPage;
