import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarked} from "@fortawesome/free-solid-svg-icons";

import {useHistory, useLocation, useParams} from "react-router-dom";
import {getTouren, getTourenAnonym} from "../../Api";
import auth from "../../Components/Auth/Auth.js";
import Pagination from "../../Components/Pagination/Pagination.js";

function Row({tour}) {
    const history = useHistory();
    const date = new Date(tour.ETA);
    const von = new Date(tour.ZeitVon);
    const bis = new Date(tour.ZeitBis);
    const status = tour.Status;
    const menge = Math.abs(tour.Menge);
    const wiegekarte = tour.Wiegekarte;

    const showDataStati = ["In Planung", "Geplant", "Abgeschlossen"];

    const datum = showDataStati.includes(status) && tour.showETA ? date.toLocaleDateString() : "";

    const getUhrzeit = () => {
        if (
            (von.getTime() === date.getTime() && bis.getTime() === date.getTime()) ||
            status === "Abgeschlossen"
        ) {
            return date.toLocaleTimeString();
        } else {
            return von.toLocaleTimeString() + " - " + bis.toLocaleTimeString();
        }
    };

    const uhrzeit = showDataStati.includes(status) ? getUhrzeit() : status;
    const showRoute = status !== "Abgeschlossen" && tour.lastPosition != null;


    function displayRouteOnMap() {
        history.push({
            pathname: `/map/${tour.ID}`,
            state: {tour}
        });
    }

    return (
        <tr className="my-5">
            <td label="Datum" className="px-6 py-4 whitespace-nowrap">
                <div className="font-medium text-gray-900">{datum}</div>
            </td>

            <td label="Uhrzeit" className="px-6 py-4 whitespace-nowrap">
                <div className="font-medium text-gray-900">{uhrzeit}</div>
            </td>

            <td label="Menge" className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center min-w-full">{menge}</div>
            </td>
            <td label="Wiegekarte" className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center min-w-full">{wiegekarte}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                {showRoute && (
                    <FontAwesomeIcon onClick={displayRouteOnMap} icon={faMapMarked}/>
                )}
            </td>
        </tr>
    );
}

function TourPage() {
    const loggedIn = auth.userLoggedIn();
    const {state, path, search} = useLocation();
    const {standort} = state ?? {standort: "Anonyme Abfrage"};
    const plzParam = new URLSearchParams(search).get("plz") ?? "";
    const [plz, setPLZ] = useState(plzParam);
    const history = useHistory();
    const {auftrag} = useParams();
    const [error, setError] = useState("");
    const [touren, setTouren] = useState({
        TotalPages: 1,
        PageNumber: 1,
        Elements: []
    });
    const [totalPages, setTotalPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [fetchOptions, setFetchOptions] = useState({
        pageNumber: 1,
        pageSize: 7,
        Search: "",
        OrderBy: "ETA",
        OrderDirection: "DESC"
    });

    const [loading, setLoading] = useState(true);


    useEffect(() => {
        if (loggedIn || plz.length > 0) {
            (async () => {
                setLoading(true);
                const touren = await (loggedIn ? getTouren(auftrag, fetchOptions) : getTourenAnonym(auftrag, plz, fetchOptions));
                setLoading(false);
                if (!touren.ok) {
                    return setError("Daten konnte nicht ermittelt werden" + (
                        loggedIn ? "" : `\n(Auftrag: ${auftrag}, Postleitzahl: ${plz} )`
                    ));
                }
                setTouren(touren);
            })();

        }
    }, [fetchOptions, auftrag, loggedIn, plz]);

    useEffect(() => {
        setTotalPages(touren.TotalPages);
        setCurrentPage(touren.PageNumber);
    }, [touren])


    function setPage(pageNumber) {
        setFetchOptions({...fetchOptions, pageNumber});
    }

    function backToAuftraege() {
        history.goBack();
    }

    function handleHeaderClick(column) {
        const sort = {OrderBy: column, OrderDirection: "ASC"};
        if (fetchOptions.OrderBy === sort.OrderBy) {
            sort.OrderDirection = fetchOptions.OrderDirection === "ASC" ? "DESC" : "ASC";
        }
        setFetchOptions({...fetchOptions, ...sort});
    }

    function getMessage() {
        if (error) {
            return error;
        }
        if (loading) {
            return "Laden...";
        }
        if (touren.Elements.length === 0) {
            return "Keine Touren gefunden";
        }
    }

    const message = getMessage();
    const plzRel = useRef(null);
    if (!loggedIn && !plz) {
        return (
            <div className={"relative bg-white py-10 px-4 bg-white shadow-xl rounded-3xl"}>
                <h1 className="text-3xl font-medium text-gray-700">
                    {standort}
                </h1>
                <form className="flex flex-col py-8 text-base text-gray-700 space-y-4 " onSubmit={(ev) => {
                    setPLZ(plzRel.current.value);
                    ev.preventDefault();
                }}>
                    <label className="sr-only">Postleitzahl</label>
                    <input
                        ref={plzRel}
                        name="PLZ"
                        type="text"
                        className="px-4 py-2 text-gray-600 border border-gray-300 focus:ring-gray-500 focus:border-gray-900 sm:text-sm rounded-md focus:outline-none"
                        placeholder="Postleitzahl der Baustelle"
                        required

                    />
                    <button
                        className={"disabled:opacity-20 flex items-center justify-center w-full px-4 py-3 text-white bg-blue-800 rounded-md focus:outline-none bg-blue-600 text-white"}
                        type={"submit"}> Prüfen
                    </button>
                </form>
            </div>
        );
    }


    if (plz && !plzParam && !loggedIn && touren.Elements.length > 0) {
        history.replace({path, search: `?plz=${plz}`});
    }

    return (
        <div className="relative ">
            {loggedIn && <button
                onClick={backToAuftraege}
                className="flex items-center justify-center px-4 py-3 text-white bg-blue-900 rounded-md focus:outline-none"
            >
                zurück
            </button>}
            <h1 className="px-4 my-8 text-3xl font-medium text-gray-700">
                {standort}
            </h1>
            {message
                ? <div className="relative h-96">
                    <span className="absolute inset-0 flex items-center justify-center">{message}</span>
                </div>
                :

                <div className="flex flex-col bg-white">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full min-h-full p-6 divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 tracking-wider text-left text-gray-500">
                                            <button className=" text-xs font-medium uppercase"
                                                    onClick={() => handleHeaderClick("ETA")}>Datum
                                            </button>
                                        </th>
                                        <th className="px-6 py-3 tracking-wider text-left text-gray-500">
                                            <button className=" text-xs font-medium uppercase"
                                                    onClick={() => handleHeaderClick("ETA")}>Uhrzeit
                                            </button>
                                        </th>
                                        <th className="px-6 py-3 tracking-wider text-left text-gray-500">
                                            <button className=" text-xs font-medium uppercase"
                                                    onClick={() => handleHeaderClick("Menge")}>Menge
                                            </button>
                                        </th>
                                        <th className="px-6 py-3 tracking-wider text-left text-gray-500">
                                            <button className=" text-xs font-medium uppercase"
                                                    onClick={() => handleHeaderClick("Wiegekarte")}>Wiegekarte
                                            </button>
                                        </th>
                                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"></th>
                                    </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                    {touren.Elements.filter(t=>!t.showETA).map(
                                        (t, key) =>
                                            t && <Row key={key} tour={t}/>
                                    )}
                                    {touren.Elements.filter(t=>t.showETA).map(
                                        (t, key) =>
                                            t && <Row key={key} tour={t}/>
                                    )}
                                    </tbody>
                                </table>
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    handlePageChange={setPage}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );

}

export default TourPage;
