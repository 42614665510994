import React, {useState, useEffect} from "react";
import {KundenListe} from "../../Components/List/KundenListe";
import {Link} from "react-router-dom";

function KundenPage() {
    return (
        <section className="">
            <Link kunden={{}} className="" to="/bestellung" key="bestellung">
                <button
                    className="inline-flex items-center justify-center p-2 my-10 text-white bg-blue-900 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    freie Bestellung
                </button>
            </Link>
            <KundenListe />
        </section>

    );
}

export default KundenPage;
