import React from "react";
import { ProvideAuth } from "./Components/Auth/ProvideAuth";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Main from "./Components/Main/Main";

import ToastProvider from "./Components/Toast/ToastProvider";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";

function App() {
  return (
    <ProvideAuth>
      <ToastProvider>
        <Router>
          <Header />
          <Main />
          <Footer></Footer>
        </Router>
      </ToastProvider>
    </ProvideAuth>
  );
}

export default App;
