import React, {useState} from "react";
import {newRegistration} from "../../Api";
import {useToast} from "../../Components/Toast/ToastProvider";
import {ClipLoader} from "react-spinners";
import {useHistory} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

function RegisterPage() {
    const toast = useToast();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState();
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        company:"",
        email: "",
        telefon: "",
        annotation:""
    });

    function handleOnChange({target: {value, name}}) {
        setFormData({...formData, [name]: value});
    }

    async function handleRegister(e) {
        e.preventDefault();
        setIsLoading(true);
        const res = await newRegistration(formData);
        if (res.ok) {
            setSuccess(true);
            toast.push("Registrierung war erfolgreich", "Success", 4000);
            setTimeout(history.push("/login"), 5000);
        } else {
            const resp = await res.json();
            const message = Object.entries(resp.ModelState)[0][1];
            toast.push(message, "Error", 4000);
        }
        setIsLoading(false);
    }

    return (
        <div className="items-center self-center justify-center px-4 py-8 my-16 shadow-md bg-gray-50 lg:px-8 md-8">
            <div className="w-full max-w-md py-2 mb-6">
                <div>
                    <h2 className="mt-1 text-3xl font-extrabold text-center text-gray-900">
                        Registrierung
                    </h2>
                </div>
                <form
                    className="mt-8 space-y-6"
                    onSubmit={handleRegister}
                    autoComplete={"off"}
                >
                    {success ? (
                        <div className="h-20 my-16 place-items-center py-18">
                            <h3 className="flex-row text-xl font-bold">
                                Ihre Anfrage wurde versendet. Wir werden uns bei Ihnen melden.
                            </h3>
                            <div className="flex place-content-around my-11">
                                <FontAwesomeIcon
                                    size="8x"
                                    color="green"
                                    icon={faCheck}
                                ></FontAwesomeIcon>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="rounded-md shadow-sm -space-y-px">
                                <div>
                                    <label htmlFor="company" className="sr-only">
                                        Firmenname
                                    </label>
                                    <input
                                        id="company"
                                        name="company"
                                        type="text"
                                        autoComplete="company"
                                        className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                        onChange={handleOnChange}
                                        placeholder="Firmenname"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="lastname" className="sr-only">
                                        Nachname
                                    </label>
                                    <input
                                        id="lastname"
                                        name="lastname"
                                        type="text"
                                        autoComplete="lastname"
                                        required
                                        className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                        onChange={handleOnChange}
                                        placeholder="Nachname"
                                    />
                                </div>
                                <div>
                                    <label for="firstname" className="sr-only">
                                        Vorname
                                    </label>
                                    <input
                                        id="firstname"
                                        name="firstname"
                                        type="text"
                                        autocomplete="firstname"
                                        className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                        onChange={handleOnChange}
                                        placeholder="Vorname"
                                    />
                                </div>
                                <div>
                                    <label for="email" className="sr-only">
                                        Email
                                    </label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autocomplete="email"
                                        required
                                        className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                        onChange={handleOnChange}
                                        placeholder="Email"
                                    />
                                </div>
                                <div>
                                    <label for="telefon" className="sr-only">
                                        Telefonnummer
                                    </label>
                                    <input
                                        id="telefon"
                                        name="telefon"
                                        type="text"
                                        autocomplete="telefon"
                                        required
                                        className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                        onChange={handleOnChange}
                                        placeholder="Telefonnummer"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="annotation" className="sr-only">
                                        Bemerkung
                                    </label>
                                    <textarea
                                        id="annotation"
                                        name="annotation"
                                        type="text"
                                        rows={5}
                                        className="relative block w-full max-w-full resize px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none rounded-b-md appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                        onChange={handleOnChange}
                                        placeholder="Bemerkung"
                                    />
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent group rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg
                        className="w-5 h-5 text-indigo-500 group-hover:text-indigo-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                      <path
                          fill-rule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                          clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                                    {isLoading ? (
                                        <ClipLoader size="20" color="#ffffff"/>
                                    ) : (
                                        "Anfragen"
                                    )}
                                </button>
                            </div>
                        </>
                    )}
                </form>
            </div>
        </div>
    );
}

export default RegisterPage;
