import React from "react";
import "./Footer.css";
import Svg from "../Svg/Svg";
import { useAuth } from "../Auth/ProvideAuth";

function Footer() {
  const auth = useAuth();
  if (!auth.user) {
    return (
      <footer className="w-full pt-1 contact footer ">
        <div className="container px-6 mx-auto">
          <div className="flex-row sm:flex sm:mt-8">
            <div className="flex flex-col justify-between mx-2 mt-8 sm:mt-0 sm:w-full sm:px-8 md:flex-row">
              <div className="flex flex-col">
                <span className="py-2 mb-2 font-bold text-gray-700 uppercase">
                  Kontakt
                </span>

                <section className="flex my-2 mt-2 space-x-2">
                  <div className="relative top-1">
                    <Svg icon="phone"></Svg>
                  </div>
                  <span className="px-1">Tel:</span>
                  <a href="tel:0231800088">0231 - 80 00 88</a>
                  <span className="px-1">Fax: </span>0231 - 80 36 99
                </section>

                <section className="flex my-2 mt-2 space-x-2">
                  <div className="relative top-1">
                    <Svg icon="mail"></Svg>
                  </div>

                  <span className="px-1">Email:</span>
                  <a href="mailto:info@bht-heitkemper.de">
                    info@bht-heitkemper.de
                  </a>
                </section>

                <section className="flex my-2 mt-2 space-x-2">
                  <div className="relative top-1">
                    <Svg icon="wa" />
                  </div>
                  <a href="https://wa.me/0231800088" className="px-1">
                    0231 - 80 00 88
                  </a>
                  <span>(während der Bürozeiten)</span>
                </section>
              </div>
              <div className="flex flex-col w-52">
                <span className="py-2 mb-2 font-bold text-gray-700 uppercase">
                  Lieferanschrift
                </span>

                <section className="flex flex-auto my-2 mt-2 space-x-2">
                  <div className="flex-wrap">
                    <span>BHT Baustoff- Handels- und </span>
                    <span className="">Transportges.mbH & Co.KG </span>
                    <div>Frydagstr. 20</div>
                    <div className="">44536 Lünen</div>
                  </div>
                </section>
              </div>
              <div className="flex flex-col w-52">
                <span className="py-2 mb-2 font-bold text-gray-700 uppercase">
                  Rechnungsanschrift
                </span>

                <section className="flex flex-auto space-x-2">
                  <div className="flex-wrap">
                    <span>BHT Baustoff- Handels- und </span>
                    <span>Transportges.mbH & Co.KG </span>
                    <div>Im Siepen 6</div>
                    <div>44339 Dortmund</div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="flex justify-center pt-2 my-9 sm:mt-9 sm:w-full sm:px-8">
            <section className="px-4 font-bold links">
              <a
                href="https://www.bht-heitkemper.de/impressum"
                target="_blank"
                rel="noreferrer"
              >
                Impressum
              </a>
            </section>
            <section className="px-4 font-bold links">
              <a
                href="https://www.bht-heitkemper.de/datenschutz"
                target="_blank"
                rel="noreferrer"
              >
                Datenschutz
              </a>
            </section>
          </div>
        </div>
        <div className="container px-6 mx-auto">
          <div className="flex flex-col items-center mt-16 border-t-2 border-gray-300">
            <div className="py-6 text-center sm:w-2/3">
              <p className="mb-2 text-sm font-bold">
                © 2021 - BHT Baustoff- Handels- und Transportges. mbH & Co.KG
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
  return <></>;
}

export default Footer;
