import User from "./User";
import { doLogin } from "../../Api";

const roles = {
  admin: "Administrator",
  user: "WebUser",
};

let user = JSON.parse(sessionStorage.getItem("user"));
let userToken = user ? user.token : null;
let userRole = user ? user.role : null;

const userLoggedIn = () => {
  return userToken != null;
};

const isAdmin = () => {
  return userLoggedIn() && userRole === roles.admin;
};

const getToken = () => {
  return userToken;
};
const logout = (cb) => {
  userToken = null;
  userRole = null;
  user = null;
  sessionStorage.clear();
  setTimeout(cb, 100); // fake async
};

const login = async (cb, username, password) => {
  try {
    if (user) {
      return cb(user);
    }
    const result = await doLogin(username, password);
    if (result.ok) {
      userToken = result.token_type + " " + result.access_token;
      userRole = result.Role;
      user = new User().fromJson({
        Benutzername: result.userName,
        Kundennummer: result.Kundennummer,
        OpheoID: result.OpheoID,
        Role: userRole,
        token: userToken,
      });

      sessionStorage.setItem("user", JSON.stringify(user));
      cb(user);
    } else {
      cb(false);
    }
  } catch (e) {
    console.log(e);
  }
};

const auth = {
  userLoggedIn,
  isAdmin,
  login,
  logout,
  getToken,
};

export { roles };
export default auth;
