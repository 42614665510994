import React, { useState, useEffect } from "react";
import { useAuth } from "../Auth/ProvideAuth";
import { useHistory, Link } from "react-router-dom";
import Svg from "../Svg/Svg";
import { roles } from "../Auth/Auth";
import "./Nav.css";

function Nav() {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 1024;
  let history = useHistory();
  let auth = useAuth();
  const { pathname } = history.location;
  const [path, setPath] = useState(pathname);
  const [isOpen, setisOpen] = useState(!isMobile);
  let user = auth.user;
  const getNavOptions = () => {
    if (!user) {
      return [
        path === "/login" || path === "/" ? (
          <Link
            className="flex items-center space-x-1 "
            to="/register"
            key="register"
            onMouseUp={() => isMobile && setisOpen(false)}
            onClick={() => setPath("/register")}
          >
            <Svg icon="register" />
            <span className="mx-3">Registrieren</span>
          </Link>
        ) : (
          <Link
            className="flex items-center space-x-1"
            onMouseUp={() => isMobile && setisOpen(false)}
            to="/login"
            key="login"
            onClick={() => setPath("/login")}
          >
            <Svg icon="login" />
            <div>Einloggen</div>
          </Link>
        ),
      ];
    } else {
      const items = [
        <Link
          className="flex items-center  space-x-1"
          onMouseUp={() => isMobile && setisOpen(false)}
          to="/changePassword"
          key="changePassword"
        >
          <Svg icon="changePwd" />
          <span className="">Passwort ändern</span>
        </Link>,
        <Link
          className="flex items-center px-10 logout-btn space-x-1"
          to="/login"
          onMouseUp={() => isMobile && setisOpen(false)}
          key="logout"
          onClick={() => auth.signout(() => history.push("/login"))}
        >
          <div className="inline-flex items-center px-2 py-2 text-base font-medium text-white bg-blue-900 border border-transparent space-x-2 whitespace-nowrap rounded-md shadow-sm hover:bg-blue-600">
            <span>Ausloggen</span>
            <Svg className="px-2" icon="logout" />
          </div>
        </Link>,
      ];
      if (user.Role === roles.admin) {
        items.unshift(
          <Link
            className="flex items-center px-10 space-x-1"
            onMouseUp={() => isMobile && setisOpen(false)}
            to="/users"
            key="users"
          >
            <Svg icon="users" />
            <span className="text-base font-medium text-gray-500 hover:text-gray-900">
              Nutzer
            </span>
          </Link>,
          <Link
            className="flex items-center px-10"
            to="/settings"
            key="settings"
          >
            <Svg icon="settings" />
            <span className="px-2">Einstellungen</span>
          </Link>
        );
      } else {
        items.unshift(
          <Link
            onMouseUp={() => isMobile && setisOpen(false)}
            className="flex items-center mr-3 "
            to="/"
            key="home"
          >
            <Svg icon="home" />
            <span className="px-2">Übersicht</span>
          </Link>
        );
      }
      return items;
    }
  };

  return (
    <div>
      <button
        type="button"
        onClick={() => setisOpen(!isOpen)}
        className="block lg:hidden"
      >
        <svg
          className="absolute fill-current top-2 right-2 w-9 h-9 focus:bg-blue-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          {isOpen && (
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
            />
          )}
          {!isOpen && (
            <path
              className="relative top-12 right-4"
              fillRule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            />
          )}
        </svg>
      </button>
      <nav
        className={`py-5 flex flex-wrap TopNav my-1 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        {getNavOptions()}
      </nav>
    </div>
  );
}

export default Nav;
