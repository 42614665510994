import React from "react";
import ReactPagination from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./pagination.css";

export default function Pagination({currentPage, totalPages, handlePageChange }) {
  return (
    <div className="">
      <ReactPagination
        activeClassName={"item active "}
        breakClassName={"item break-me "}
        breakLabel={"..."}
        containerClassName={"pagination"}
        disabledClassName={"disabled-page"}
        marginPagesDisplayed={2}
        forcePage={currentPage-1}
        nextClassName={"item next "}
        nextLabel={
          <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 14 }} />
        }
        onPageChange={({ selected }) => handlePageChange(selected + 1)}
        pageCount={totalPages}
        pageClassName={"item pagination-page "}
        pageRangeDisplayed={2}
        previousClassName={"item previous"}
        previousLabel={
          <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: 14 }} />
        }
      />
    </div>
  );
}
