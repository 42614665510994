import {roles} from "../Auth/Auth.js";

export const defaultRole = roles.user;

function RoleSelect({currentValue = defaultRole, handleChange, ...others}) {
    return (
        <select
            {...others}
            onChange={({target: {value}}) =>
                handleChange(roles[value])
            }
        >
            {Object.entries(roles).map(([key, label]) => {
                return (<option value={key} selected={currentValue == label}>{label}</option>);
            })}
        </select>
    );
}

export default RoleSelect;