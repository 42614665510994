import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {getOrderData, getKunden} from "../../Api";
import "./kundenlist.css";
import {debounce} from "lodash/function.js";
import Pagination from "../Pagination/Pagination.js";

function Row({kunde}) {
    const history = useHistory();
    const [selectedStandort, setSelectedStandort] = useState(null);

    useEffect(() => {
        if (null == kunde.Standorte.find(s => s.ID == selectedStandort)) {
            setSelectedStandort(kunde.Standorte?.[0]?.ID);
        }
    }, [kunde]);

    async function navigateToOrderPage() {
        const orderData = await getOrderData(kunde.ID, selectedStandort);
        history.push({pathname: "/bestellung", state: {orderData}});
    }

    function navigateToAuftragPage() {
        history.push({
            pathname: `/auftraege/${kunde.ID}/${selectedStandort}`,
            state: {
                kunde,
                standort: kunde.Standorte.find(s => s.ID == selectedStandort)
            }
        });
    }

    const selectionChanged = (newValue) => {
        setSelectedStandort(newValue);
    };

    return (
        kunde && (
            <tr className="flex-grow my-12">
                <td
                    label="Kunde"
                    className="p-3 border border-grey-light hover:bg-gray-100"
                >
                    {kunde.Name}
                </td>
                <td
                    label="Standorte"
                    className="p-3 truncate border border-grey-light hover:bg-gray-100"
                >
                    {kunde.Standorte.length > 0
                        ?
                        (<select
                            id=""
                            name=""
                            className="bg-white"
                            onChange={(event) => selectionChanged(event.target.value)}
                        >

                            {kunde.Standorte.map(({Kuerzel, ID}) => (
                                <option key={ID} className="bg-white" value={ID} selected={ID == selectedStandort}>
                                    {Kuerzel}{" "}
                                </option>
                            ))}
                        </select>)
                        :
                        <span>Keine Standorte</span>
                    }
                </td>
                <td className="p-3 text-red-400 border cursor-pointer border-grey-light hover:bg-gray-100 hover:text-red-600 hover:font-medium">
                    <button
                        onClick={navigateToAuftragPage}
                        className={`${
                            kunde.Standorte.length !== 0
                                ? "text-blue-600 hover:text-blue-900"
                                : "text-gray-300"
                        }`}
                        disabled={kunde.Standorte.length === 0}
                    >
                        Übersicht
                    </button>
                </td>
                <td className="p-3 text-red-400 border cursor-pointer border-grey-light hover:bg-gray-100 hover:text-red-600 hover:font-medium">
                    <button
                        disabled={kunde.Standorte.length === 0}
                        onClick={navigateToOrderPage}
                        className={`${
                            kunde.Standorte.length !== 0
                                ? "text-blue-600 hover:text-blue-900"
                                : "text-gray-300"
                        }`}
                    >
                        bestellen
                    </button>
                </td>
            </tr>
        )
    );
}

export function KundenListe() {
    const [totalPages, setTotalPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [fetchOptions, setFetchOptions] = useState({
        PageNumber: 1,
        Search: "",
        OrderBy: "Name",
        OrderDerection: "ASC",
    });
    var [kunden, setKunden] = useState({
        TotalPages: 1,
        PageNumber: 1,
        Elements: []
    });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchKundenData = async () => {
            try {
                const kunden = await getKunden({
                        ...fetchOptions
                    }
                );
                setKunden(kunden);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }
        fetchKundenData();
    }, [fetchOptions, loading]);
    useEffect(() => {
        const {TotalPages, PageNumber} = kunden;
        setTotalPages(TotalPages);
        setCurrentPage(PageNumber);
    }, [kunden]);

    async function setPage(pageNumber) {
        setFetchOptions({...fetchOptions, PageNumber: pageNumber});
    }

    const setSearchDebounced = debounce((s) => {
        setFetchOptions({...fetchOptions, Search: s, PageNumber: 1},);
    }, 300);

    function handleSearch({target: {value}}) {
        setSearchDebounced(value);
    }

    function handleOrderBy(column) {
        const sort = {OrderBy: column, OrderDirection: "ASC"};
        if (fetchOptions.OrderBy == sort.OrderBy) {
            sort.OrderDirection = fetchOptions.OrderDirection == "ASC" ? "DESC" : "ASC";
        }
        setFetchOptions({...fetchOptions, ...sort});
    }

    return (
        <div className="lg:w-full kundenliste">
            <input
                type="text"
                placeholder="Suchen"
                onChange={handleSearch}
                name=""
                className="relative block px-3 py-2 my-3 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
            />
            <table className="flex flex-row overflow-hidden bg-white rounded-lg sm:bg-white sm:shadow-lg">
                <thead className="text-black">
                {kunden &&
                    <tr
                        key={"header"}
                        className="flex flex-col mb-2 bg-teal-400 rounded-l-lg flex-nowrap wrap sm:table-row sm:rounded-none sm:mb-3"
                    >
                        <th onClick={(e) => handleOrderBy("Name")} className="p-3 text-left">
                            Name
                        </th>
                        <th className="p-3 text-left">
                            Standorte
                        </th>
                        <th className="p-3 text-left" width="110px"></th>
                    </tr>
                }
                </thead>
                <tbody className="">
                {kunden?.Elements &&
                    kunden.Elements.map(
                        (kunde, key) => kunde && <Row key={key} kunde={kunde}/>
                    )}
                </tbody>
            </table>
            <div className="bg-white p-4">
                <Pagination totalPages={totalPages} currentPage={currentPage}
                            handlePageChange={setPage}/>
            </div>
        </div>
    );
}
