import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {useToast} from "../../Components/Toast/ToastProvider";
import {roles} from "../../Components/Auth/Auth.js";
import {updateUser, getKunden, getKunde, setPassword} from "../../Api";
import RoleSelect from "../../Components/RoleSelect/RoleSelect.js";

function AlterUserPage() {
    const history = useHistory();
    const toast = useToast();
    const {user} = history.location;
    const [currentUser, setCurrentUser] = useState({...user, FreigegebeneStandorte: [...user.FreigegebeneStandorte]});
    const [kundenStandorte, setKundenStandorte] = useState([])
    const [loading, setLoading] = useState(true);
    const [hasChanges, setHasChanges] = useState(false);


    const loadStandortMeta = async () => {
        const {Elements: kunden} = await getKunde(currentUser.UserID, {noCache:true});
        setKundenStandorte(kunden.filter(k => k.Standorte.length > 0));
    }



    const doUpdate = async () =>{
        const res = await updateUser({...currentUser});
        if (res?.ok) {
            console.log(res);
            const inputs = document.querySelectorAll("input");
            inputs.forEach((i) => (i.value = ""));
            toast.push("Nutzer wurde erfolgreich aktualisiert", "Success", 4000);
        } else {
            toast.push("Nutzer konnte nicht  geändert werden", "Error", 4000);
        }
    };

    useEffect(() => {
        loadStandortMeta();
        setLoading(false);
    }, []);

    useEffect( ()=>{
        const changes = Object.keys(currentUser).some(k=>{
            if (Array.isArray(user[k])){
                const a1 = user[k];
                const a2 = currentUser[k];
                return a1.length != a2.length || a1.some(v=>!a2.includes(v)) || a2.some(v=>!a1.includes(v))
            }
            else{
                return user[k]!=currentUser[k];
            }
        });
        setHasChanges(changes);
    },[currentUser])

    const handleStandortChange = (id = null) => {
        let standorte = [...currentUser.FreigegebeneStandorte];
        if (id == null) {
            standorte = [];
        } else {
            if (standorte.includes(id)) {
                standorte.splice(standorte.indexOf(id), 1);
            } else {
                standorte.push(id);
                standorte.sort();
            }
        }
        setCurrentUser({...currentUser, FreigegebeneStandorte: standorte});
    }

    const handleTextChange = ({id,value}) => {
        setCurrentUser({...currentUser, [id]: value});
    }
    return (
        <div className="">
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg font-medium text-gray-900 leading-6">
                        Benutzerinformation
                    </h3>
                    <p className="max-w-2xl mt-1 text-sm text-gray-500">
                        Benutzerdaten ändern
                    </p>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                <label htmlFor={"Benutzername"}> Benutzername</label>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <input type={"text"}
                                       className = "w-full px-3 py-2  border-b focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10"
                                       id={"Benutzername"}
                                       value={currentUser?.Benutzername ?? ""}
                                       onChange={(ev) => handleTextChange(ev.target)}/>
                            </dd>
                        </div>
                        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                <label htmlFor={"Kundennummer"}> Kundennummer</label>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <input type={"text"}
                                       className = "w-full px-3 py-2  border-b focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10"
                                       id={"Kundennummer"}
                                       value={currentUser?.Kundennummer ??""}
                                       onChange={(ev) => handleTextChange(ev.target)}/>
                            </dd>
                        </div>
                        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                <label htmlFor={"Rolle"}> Role</label>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <RoleSelect
                                    id={"Rolle"}
                                    className = "w-full px-3 py-2 appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10"
                                    handleChange={value=>setCurrentUser({...currentUser, Role: value})}
                                    currentValue={currentUser?.Role}
                                />
                            </dd>
                        </div>
                        <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500"><label htmlFor={"all"}>Standorte</label>
                            </dt>
                            {!loading ?
                                <>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                        <label htmlFor={"all"}>Alle</label>
                                    </dd>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                        <input id={"all"} type={"checkbox"}
                                               readOnly={currentUser.FreigegebeneStandorte.length == 0}
                                               checked={currentUser.FreigegebeneStandorte.length == 0}
                                               onChange={() => handleStandortChange()}/>
                                    </dd>
                                </> :
                                <span>Lade Standortinformationen</span>
                            }
                            {
                                kundenStandorte.map(({Standorte, ...kunde}) => {
                                    return Standorte.map((s, i) =>
                                        <>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 text-right">{i == 0 && kunde.Kürzel}</dd>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                                <label htmlFor={"Standort_" + s.ID}>{s.Kuerzel}</label>
                                            </dd>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                                <input id={"Standort_" + s.ID}
                                                       type={"checkbox"}
                                                       checked={currentUser.FreigegebeneStandorte.includes(s.ID)}
                                                       onChange={() => handleStandortChange(s.ID)}
                                                />
                                            </dd>
                                        </>);
                                })
                            }

                        </div>
                    </dl>
                </div>
                <div className="border-t border-gray-200">
                    <div className="px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                        <button
                            onClick={()=> {
                                history.goBack();
                            }}
                            className="animate-wiggle hover:wiggle hover:animate-wiggle relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-900 group rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            zurück
                        </button>
                        <button
                            onClick={()=>{
                                doUpdate();
                                history.goBack();
                            }}
                            disabled={hasChanges?false:"disabled"}
                            className="disabled:opacity-50 animate-wiggle hover:wiggle hover:animate-wiggle relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-900 group rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            speichern
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AlterUserPage;
