import React from "react";
import "./Main.css";
import {Switch, Route, Redirect, useHistory} from "react-router-dom";
import PrivateRoute from "../Auth/PrivateRoute";
import Error404 from "../../Pages/Errors/Error404";
import LogInPage from "../../Pages/Public/LogInPage";
import RegisterPage from "../../Pages/Public/RegisterPage";
import BestellungPage from "../../Pages/User/BestellungPage";
import KundenPage from "../../Pages/User/KundenPage";
import AuftragPage from "../../Pages/User/AuftragPage";
import TourPage from "../../Pages/User/TourPage";
import AlterUserPage from "../../Pages/Admin/AlterUserPage";
import SettingsPage from "../../Pages/Admin/SettingsPage";
import UsersPage from "../../Pages/Admin/UsersPage";
import ChangePasswordPage from "../../Pages/User/ChangePasswordPage";
import AdminRoute from "../Auth/AdminRoute";
import {useAuth} from "../Auth/ProvideAuth";
import {roles} from "../Auth/Auth";
import TestPage from "../../Pages/Public/TestPage";
import {TourenMap} from "../../Components/TourenMap/TourenMap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

function Main() {
    let auth = useAuth();
    const history = useHistory();
    let redirectPath = auth.user
        ? auth.user.Role === roles.admin
            ? "/users"
            : "/kunden"
        : "/login";

    return (
        <main className="h-full flex px-4 py-12 bg-gray-100 divide-gray-50 lg:px-24 place-content-center">
            {auth.user && (
                <button
                    className="absolute px-8 py-0 text-white bg-blue-900 left-4 top-24 rounded-md hover:text-white hover:bg-blue-600"
                    onClick={() => history.goBack()}
                >
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                </button>
            )}
            <Switch>
                <Route path="/login">
                    <LogInPage/>
                </Route>
                <Route path="/map/:auftrag">
                    <TourenMap/>
                </Route>
                <Route path="/register">
                    <RegisterPage/>
                </Route>
                <Route path="/test">
                    <TestPage/>
                </Route>
                <Route
                    exact={true}
                    path="/"
                    render={({location}) => (
                        <Redirect
                            to={{
                                pathname: redirectPath,
                                state: {from: location},
                            }}
                        />
                    )}
                />
                <PrivateRoute path="/kunden">
                    <KundenPage/>
                </PrivateRoute>
                <PrivateRoute path="/bestellung">
                    <BestellungPage/>
                </PrivateRoute>
                <PrivateRoute
                    path="/auftraege/:kundenId/:standortId"
                    children={<AuftragPage/>}
                />
                <Route path="/tour/:auftrag" children={<TourPage/>}/>
                <PrivateRoute path="/changepassword">
                    <ChangePasswordPage/>
                </PrivateRoute>
                <AdminRoute path="/users">
                    <UsersPage/>
                </AdminRoute>

                <AdminRoute path="/settings">
                    <SettingsPage/>
                </AdminRoute>
                <AdminRoute path="/alteruser/:userid" children={<AlterUserPage/>}/>

                <Route path="*">
                    <Error404/>
                </Route>
            </Switch>
        </main>
    );
}

export default Main;
