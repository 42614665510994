import React, { useEffect, useState } from "react";
import { updatePassword } from "../../Api";
import { useToast } from "../../Components/Toast/ToastProvider";

function ChangePasswordPage() {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  useEffect(validateForm, [formData]);
  const [passwordsValid, setPasswordValid] = useState(false);
  const toast = useToast();

  function handleInputChange({ target: { name, value } }) {
    setFormData({ ...formData, [name]: value });
    validateForm();
  }

  async function handlePasswordUpdate() {
    const res = await updatePassword(formData);
    if (res.ok) {
      toast.push("Passwort wurde erfolgreich geändert", "Success", 4000);
    } else {
      const resp = await res.json();
      const message = Object.entries(resp.ModelState)[0][1];
      toast.push(message, "Error", 4000);
    }
  }
  function validateForm() {
    if (
      formData.oldPassword &&
      formData.newPassword &&
      formData.confirmPassword &&
      formData.confirmPassword === formData.newPassword
    ) {
      setPasswordValid(true);
    }
  }
  return (
    <div className="mt-24 max-w-screen-xl py-9">
      <h3 className="mb-8 text-3xl font-medium text-gray-700">
        Passwort ändern
      </h3>
      <section className="">
        <div className="shadow-lg">
          <div className="bg-white space-y-6">
            <hr />
            <div className="items-center w-full p-8 text-gray-500 md:inline-flex space-y-4 md:space-y-0">
              <div className="w-full max-w-sm pl-2 mx-auto md:w-5/12 md:pl-9 space-y-5 md:inline-flex">
                <div className="inline-flex w-full border-b">
                  <input
                    onChange={handleInputChange}
                    onSelect={handleInputChange}
                    name="oldPassword"
                    type="password"
                    className="w-11/12 p-2 ml-4 focus:outline-none focus:text-gray-600"
                    placeholder="Altes Passwort"
                  />
                </div>
              </div>
              <div className="w-full max-w-sm pl-2 mx-auto md:w-5/12 md:pl-9 space-y-5 md:inline-flex">
                <div className="inline-flex w-full border-b">
                  <input
                    onChange={handleInputChange}
                    name="newPassword"
                    type="password"
                    className="w-11/12 p-2 ml-4 focus:outline-none focus:text-gray-600"
                    placeholder="Neues Passwort"
                  />
                </div>
              </div>

              <div className="w-full max-w-sm pl-2 mx-auto md:w-5/12 md:pl-9 space-y-5 md:inline-flex">
                <div className="inline-flex w-full border-b">
                  <input
                    onChange={handleInputChange}
                    name="confirmPassword"
                    type="password"
                    className="w-11/12 p-2 ml-4 focus:outline-none focus:text-gray-600"
                    placeholder="Neues Passwort wiederholen"
                  />
                </div>
              </div>

              <div className="block text-center place-items-center">
                <button
                  onClick={handlePasswordUpdate}
                  disabled={!passwordsValid}
                  className={`inline-flex items-center content-around w-full max-w-sm px-4 py-2 mx-auto text-center text-white ${
                    passwordsValid ? "bg-blue-800" : "bg-blue-400 "
                  } rounded-md focus:outline-none md:float-right place-content-center`}
                >
                  Aktualisieren
                </button>
              </div>
            </div>

            <hr />
          </div>
        </div>
      </section>
    </div>
  );
}

export default ChangePasswordPage;
