import React, { useState, useEffect, useContext, createContext } from "react";
import { emptyCache } from "../../Api";
import auth from "./Auth";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const userSession = JSON.parse(sessionStorage.getItem("user"));
  const [user, setUser] = useState(userSession ? userSession : null);

  useEffect(() => {
    if (userSession) {
      setUser(userSession);
    }
  }, []);
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (username, password, cb) => {
    return auth.login(
      (user) => {
        if (user) {
          setUser(user);
          cb(user);
        } else {
          cb(false);
        }
      },
      username,
      password
    );
  };

  const signout = (cb) => {
    emptyCache();
    return auth.logout(() => setUser(null), cb());
  };

  // Return the user object and auth methods
  return {
    user,
    signin,
    signout,
  };
}
