import React, {useEffect, useState} from "react";
import ImageUploader from "../../Components/ImageUploader/ImageUploader";
import {submitOrderData} from "../../Api";

import {useToast} from "../../Components/Toast/ToastProvider";

import {useHistory, useLocation} from "react-router-dom";
import {ClipLoader} from "react-spinners";

import Select from "react-select";

function ArtikelDropDown({artikel, handleArtikelSelectChange}) {
    const [selectedArtikel, setSelectedArtikel] = useState({
        value: null,
        label: null,
    });
    const [artikelOptions, setArtikelOptions] = useState([]);
    const [placeholder] = useState("Artikel wählen");
    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() => {
        if (artikel.length === 0) {
            setArtikelOptions([]);
            setSelectedArtikel({value: null, label: "Keine Artikel vorhanden"});
        } else {
            setSelectedArtikel({value: null, label: "Artikel wählen"});
            setArtikelOptions(artikel.map((a) => ({value: a, label: a.Name})));
        }
        setIsDisabled(artikel.length == 0);
    }, [artikel]);

    function handleArtikel(artikelOption) {
        handleArtikelSelectChange(artikelOption);
        setSelectedArtikel(artikelOption);
    }

    return (
        <Select
            key="SArtikel"
            name="SArtikel"
            className=""
            noOptionsMessage={() => "Keine Artikel vorhanden"}
            value={selectedArtikel}
            onChange={handleArtikel}
            placeholder={placeholder}
            isDisabled={isDisabled}
            options={artikelOptions}
        ></Select>
    );
}

export function BestellungPage() {
    const location = useLocation();
    const {orderData} = location.state ?? {orderData: null};

    const toDashedString = (d) => {
        const year = `${d.getFullYear()}`.padStart(4,'0');
        const month = `${d.getMonth() + 1}`.padStart(2,'0');
        const day = `${d.getDate()}`.padStart(2,'0');
    return `${year}-${month}-${day}`;
    }
    const toTimeString = (d) => {
        return d.toLocaleTimeString("de", {timeStyle: "short"});
    }
    const min = new Date();
    const defDate = new Date();
    defDate.setHours(0, 0, 0, 0);
    defDate.setDate(defDate.getDate() + 2)
    const initialFormData = {
        KundenId: "",
        StandortId: "",
        ArtikelId: "",
        TransportZielId: "",
        isFreieBestellung: true,
        SKunde: "",
        SStandort: "",
        SArtikel: "",
        STransportZiel: "",
        Termin: new Date(defDate),
        TerminEnde: new Date(defDate),
        HinweisRechnung: "",
        HinweisFahrer: "",
        HinweisDispo: "",
        Typ: "Entladung",
        Menge: 28,
        Einheit: "Tonnen",
        Sondermenge: null,
        IsSondermenge: false,
    };

    const toast = useToast();

    const history = useHistory();
    const [innerMenge, setInnerMenge] = useState(initialFormData.Menge);

    const [formData, setFormdata] = useState(initialFormData);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, toggleIsLoading] = useState(false);
    const [artikel, setArtikel] = useState(formData.Typ === "Entladung" ? orderData?.EntladeArtikel : orderData?.BeladeArtikel);
    const [imageFile, setImageFile] = useState();
    const [showTransportInput, toggleTransportInput] = useState(false);
    const [entladeStandorte, setEntladeStandorte] = useState([
        {value: null, label: null},
    ]);

    useEffect(() => {
        if (orderData) {
            setFormdata(f => {
                return {
                    ...f,
                    isFreieBestellung: false,
                    SStandort: orderData.Standort.Kuerzel,
                    StandortId: orderData.Standort.ID,
                    SKunde: orderData.Kunde.Name,
                    KundenId: orderData.Kunde.ID,
                }
            });
        }
    }, [orderData]);

// Valdiate Form
    useEffect(() => {
        const {SStandort, SKunde, SArtikel, STransportZiel, Typ, ArtikelId, Termin,TerminEnde} = formData;
        const requiredFormData = {SStandort, SKunde, artikel:SArtikel+ArtikelId};
        if (Typ == "Transport") {
            requiredFormData.STransportZiel = STransportZiel;
        }
        const requiredAreSet = Object.values(requiredFormData).every(Boolean);
        const timingFits = new Date()<=Termin && Termin<=TerminEnde;
        setIsFormValid(requiredAreSet&& timingFits);
    }, [formData])


    function createSelectOptions(item) {
        if (item && item.length > 0) {
            return item.map((s) => ({value: s, label: s.Name}));
        }
        return [];
    }

    function doUpdate(newKVP = {}) {
        const newData = {...formData, ...newKVP};
        setFormdata(newData);
    }

    function handleRadioButtons({id: Typ}) {
        if (!formData.isFreieBestellung) {
            if (Typ === "Entladung") {
                setArtikel(orderData.EntladeArtikel);
            } else {
                setArtikel(orderData.BeladeArtikel);
            }
            if (Typ === "Transport") {
                const entladeStandortOptions = createSelectOptions(
                    artikel.EntladeStandorte
                );
                setEntladeStandorte(entladeStandortOptions);
                toggleTransportInput(true);
            } else {
                toggleTransportInput(false);
            }
        }
        doUpdate({Typ});
    }

    function handleIndepenent(key, value) {
        doUpdate({[key]: value});
    }

    function handleMenge(key, value) {
        const {Menge, Sondermenge, IsSondermenge} = formData;
        const newData = {Menge, Sondermenge, IsSondermenge};
        if (key == "Menge" && (value < 28 || value % 28 != 0)) {
            newData.Sondermenge = value;
            newData.IsSondermenge = true;
            value = null;
        }
        if (key == "IsSondermenge") {
            if (value) {
                newData.Sondermenge = Menge;
                newData.Menge = null;
            } else {
                newData.Menge = Math.ceil(Sondermenge / 28) * 28;
                newData.Sondermenge = null;
            }
        }
        newData[key] = value;
        setInnerMenge(newData.IsSondermenge ? newData.Sondermenge : newData.Menge);
        doUpdate(newData);
    }


    function handleDateTime(key, value) {
        const {Termin, TerminEnde} = formData;
        const newData = {Termin, TerminEnde};
        if (key == "Termin" && value > TerminEnde) {
            newData.TerminEnde = value;
        }
        if (key == "TerminEnde" && value < Termin) {
            newData.Termin = value;
        }
        newData[key] = value;
        doUpdate(newData);
    }

    function handleDate(key, date) {
        if (date!="") {
            const value = new Date(date);

            if (formData[key]) {
                value.setHours(formData[key].getHours());
                value.setMinutes(formData[key].getMinutes());
            }
            handleDateTime(key, value);
        }
    }

    function handleTime(key, time) {
        const value = formData[key];
        value.setHours(...time.split(':').map(x => parseInt(x)));
        handleDateTime(key, value);
    }


    function handleArtikelSelectChange(artikelOption) {
        setFormdata({
            ...formData,
            ArtikelId: artikelOption.value.ID,
        });
    }

    async function submitOrder() {
        toggleIsLoading(true);
        const res = await submitOrderData({...formData, imageBase64: imageFile});
        if (res.ok) {
            toast.push("Bestellung wurde erfolgreich gesendet", "Success", 4000);
            setTimeout(() => history.push("/"), 1000);
            toggleIsLoading(false);
        } else {
            toast.push("Bestellung konnte nicht gesendet werden", "Error", 4000);
            toggleIsLoading(false);
        }
    }


    return (
        <div className="flex flex-col justify-center my-6 overflow-x-hidden py-10 px-4 bg-white shadow-xl rounded-3xl">
            <div className="flex items-center space-x-5">
                <div className="self-start block pl-2 text-xl font-semibold text-gray-700">
                    <h1 className="leading-relaxed">Bestellerfassung</h1>
                    <h2 className="text-sm font-normal leading-relaxed text-gray-500">
                        {formData.isFreieBestellung
                            ? "freie Bestellerfassung"
                            : `für ${formData.SKunde}, ${formData.SStandort}`
                        }
                    </h2>
                </div>
            </div>
            <div className="divide-y divide-gray-200 border-b border-gray-200">
                {formData.isFreieBestellung && (
                    <div className="py-8 text-base text-gray-700 leading-6 space-y-4 sm:text-lg sm:leading-7">
                        <div className="flex flex-col">
                            <label className="leading-loose">Kunde</label>
                            <input
                                name="SKunde"
                                value={orderData ? orderData.Kunde.Name : formData.SKunde}
                                onChange={(ev) => handleIndepenent("SKunde", ev.target.value)}
                                type="text"
                                className="px-4 py-2 text-gray-600 border border-gray-300 focus:ring-gray-500 focus:border-gray-900 sm:text-sm rounded-md focus:outline-none"
                                placeholder="Kunde"
                                required
                                disabled={formData.isFreieBestellung ? false : true}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="leading-loose">Standort</label>
                            <input
                                name="SStandort"
                                onChange={(ev) => handleIndepenent("SStandort", ev.target.value)}
                                type="text"
                                className="px-4 py-2 text-gray-600 border border-gray-300 focus:ring-gray-500 focus:border-gray-900 sm:text-sm rounded-md focus:outline-none"
                                placeholder="Standort"
                                required
                            />
                        </div>
                    </div>
                )}
                <div className="py-8">
                    <div className="flex place-content-between pb-4">
                        <label htmlFor="Entladung" className="text-xs lg:text-lg">
                            <input
                                onChange={ev => handleRadioButtons(ev.target)}
                                id="Entladung"
                                type="radio"
                                className="mr-2"
                                checked={formData.Typ === "Entladung"}
                            />

                            Anlieferung
                        </label>
                        <label className="text-xs lg:text-lg" htmlFor="Beladung">
                            <input
                                onChange={ev => handleRadioButtons(ev.target)}
                                id="Beladung"
                                type="radio"
                                className="mr-2"
                                checked={formData.Typ === "Beladung"}
                            />

                            Entsorgung
                        </label>
                        <label className="text-xs lg:text-lg" htmlFor="Transport">
                            <input
                                onChange={ev => handleRadioButtons(ev.target)}
                                id="Transport"
                                type="radio"
                                className="mr-2"
                                checked={formData.Typ === "Transport"}
                            />

                            Transport
                        </label>
                    </div>

                    <div className="flex flex-col">
                        {formData.isFreieBestellung ? (
                            <div className="flex flex-col">
                                <input
                                    name="SArtikel"
                                    onChange={(ev) => handleIndepenent("SArtikel", ev.target.value)}
                                    value={formData.SArtikel}
                                    type="text"
                                    className="px-4 py-2 text-gray-600 border border-gray-300 focus:shadow-none focus:ring-gray-500 focus:border-gray-900 sm:text-sm rounded-md focus:outline-none"
                                    placeholder="Artikel"
                                    required
                                />
                                {formData.Typ === "Transport" && (
                                    <div className="flex">
                                        <input
                                            onChange={(ev) => handleIndepenent("STransportZiel", ev.target.value)}
                                            value={formData.STransportZiel}
                                            placeholder="Transportziel"
                                            className="w-full px-4 py-2 my-3 text-gray-600 border border-gray-300 focus:shadow-none focus:ring-gray-500 focus:border-gray-900 sm:text-sm rounded-md focus:outline-none"
                                            type="text"
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="py-6 border-b border-gray-200 divide-y divide-gray-200">
                                <div className="flex items-center space-x-4">
                                    <div className="w-full">
                                        <ArtikelDropDown
                                            handleArtikelSelectChange={
                                                handleArtikelSelectChange
                                            }
                                            artikel={artikel}
                                        ></ArtikelDropDown>
                                    </div>
                                    {showTransportInput && (
                                        <div className="w-full">
                                            <Select
                                                className="text-xs"
                                                placeholder={
                                                    artikel.length === 0
                                                        ? "Keine Standorte vorhanden"
                                                        : "Transportziel wählen"
                                                }
                                                isDisabled={
                                                    artikel.length === 0 ? true : false
                                                }
                                                options={entladeStandorte}
                                            ></Select>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="flex flex-col">
                        <label className="leading-loose"
                               htmlFor={formData.IsSondermenge ? "Sondermenge" : "Menge"}>Menge</label>
                        <div className="flex place-content-between">
                            <div className="flex flex-grow text-gray-400 focus-within:text-gray-600">
                                {formData.IsSondermenge
                                    ? <input
                                        name="Sondermenge"
                                        className="w-full text-xl font-medium text-gray-600 border border-gray-300 lg:w-24 pl-4 place-content-center focus:shadow-none rounded-md"
                                        type="number"
                                        onChange={(ev) => setInnerMenge((parseInt(ev.target.value)))}
                                        onBlur={(ev) => handleMenge("Sondermenge", parseInt(ev.target.value))}
                                        value={innerMenge}
                                        min="1"
                                        step={1}

                                    />
                                    : <input
                                        name="Menge"
                                        className="w-full text-xl font-medium text-gray-600 border border-gray-300 lg:w-24 pl-4 place-content-center focus:shadow-none rounded-md"
                                        type="number"
                                        onChange={(ev) => setInnerMenge((parseInt(ev.target.value)))}
                                        onBlur={(ev) => handleMenge("Menge", parseInt(ev.target.value))}
                                        value={innerMenge}
                                        step="28"
                                        min="28"

                                    />
                                }
                                <span className="px-4 py-2 text-black whitespace-no-wrap ">Tonnen</span>
                            </div>

                            <label className="leading-loose" htmlFor={"IsSondermenge"}>
                                <input
                                    name="IsSondermenge"
                                    checked={formData.IsSondermenge}
                                    className="mr-1"
                                    type="checkbox"
                                    onChange={(ev) => handleMenge("IsSondermenge", !!ev.target.checked)}
                                />
                                Sondermenge
                            </label>
                        </div>
                    </div>
                </div>
                <div className="py-8">
                    <div className="flex place-content-between gap-x-1">
                        <div className="flex flex-col ">
                            <label className="leading-loose">Termin ab</label>
                            <div className="relative text-gray-400 focus-within:text-gray-600">
                                <input
                                    name="Termin"
                                    min={toDashedString(min)}
                                    type="date"
                                    onChange={(ev) => handleDate("Termin", ev.target.value)}
                                    value={toDashedString(formData.Termin)}
                                    className="flex-shrink text-center text-gray-600 border border-gray-300 focus:ring-gray-500 focus:border-gray-900 sm:text-sm rounded-md focus:outline-none"
                                    required
                                />
                                <input
                                    name="Termin"
                                    min={toDashedString(min)}
                                    type="time"
                                    onChange={(ev) => handleTime("Termin", ev.target.value)}
                                    value={toTimeString(formData.Termin)}
                                    className="flex-shrink text-center text-gray-600 border border-gray-300 focus:ring-gray-500 focus:border-gray-900 sm:text-sm rounded-md focus:outline-none"
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <label className="leading-loose">Termin bis</label>
                            <div className="relative text-gray-400 focus-within:text-gray-600">
                                <input
                                    name="TerminEnde"
                                    min={toDashedString(min)}
                                    onChange={(ev) => handleDate("TerminEnde", ev.target.value)}
                                    value={toDashedString(formData.TerminEnde)}
                                    type="date"
                                    className="flex-shrink text-center text-gray-600 border border-gray-300 focus:ring-gray-500 focus:border-gray-900 sm:text-sm rounded-md focus:outline-none"
                                    required
                                />
                                <input
                                    name="TerminEnde"
                                    min={toDashedString(min)}
                                    type="time"
                                    onChange={(ev) => handleTime("TerminEnde", ev.target.value)}
                                    value={toTimeString(formData.TerminEnde)}
                                    className="flex-shrink text-center text-gray-600 border border-gray-300 focus:ring-gray-500 focus:border-gray-900 sm:text-sm rounded-md focus:outline-none"
                                    required
                                />

                            </div>
                        </div>

                    </div>
                </div>
                <div className="py-8">
                    <div className="flex flex-col">
                        <label className="leading-loose">Rechnungskommentar</label>
                        <input
                            name="HinweisRechnung"
                            type="textarea"
                            className="h-24 px-4 py-2 text-gray-600 border border-gray-300 focus:ring-gray-500 focus:border-gray-900 sm:text-sm rounded-md focus:outline-none"
                            onChange={(ev) => handleIndepenent("HinweisRechnung", ev.target.value)}
                            placeholder="Optional"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="leading-loose">Fahrerkommentar</label>
                        <input
                            name="HinweisFahrer"
                            type="textarea"
                            onChange={(ev) => handleIndepenent("HinweisFahrer", ev.target.value)}
                            className="h-24 px-4 py-2 text-gray-600 border border-gray-300 focus:ring-gray-500 focus:border-gray-900 sm:text-sm rounded-md focus:outline-none"
                            placeholder="Optional"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="leading-loose">Dipositionshinweis</label>
                        <input
                            name="HinweisDispo"
                            type="textarea"
                            onChange={(ev) => handleIndepenent("HinweisDispo", ev.target.value)}
                            className="h-24 px-4 py-2 text-gray-600 border border-gray-300 focus:ring-gray-500 focus:border-gray-900 sm:text-sm rounded-md focus:outline-none"
                            placeholder="Optional"
                        />
                    </div>
                </div>
                <div className="py-8">
                    <div className="flex flex-col">
                        <ImageUploader setImageFile={setImageFile}></ImageUploader>
                    </div>
                </div>
            </div>

            <div className="flex items-center pt-4 space-x-4">
                <button
                    className="flex items-center justify-center w-full px-4 py-3 text-gray-900 bg-gray-300 rounded-md focus:outline-none"
                    onClick={() => history.goBack()}>
                    <svg
                        className="w-6 h-6 mr-3"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                    Abbrechen
                </button>
                <button
                    onClick={submitOrder}
                    className={`disabled:opacity-20 flex items-center justify-center w-full px-4 py-3 text-white bg-blue-800 rounded-md focus:outline-none ${"bg-blue-600 text-white"}`}
                    disabled={!isFormValid && !isLoading}
                >
                    {isLoading ? (
                        <ClipLoader size="20" color="#ffffff"/>
                    ) : (
                        "Senden"
                    )}
                </button>
            </div>
        </div>
    );
}

export default BestellungPage;
