export default class User {
    Benutzername = null;
    Kundennummer = null;
    OpheoID = null;
    Role = null;
    token=null;

    fromJson(json) {
        this.Benutzername = json?.Benutzername;
        this.Kundennummer = json?.Kundennummer ?? "";
        this.OpheoID = parseInt(json?.OpheoID ?? 0);
        this.Role = json?.Role;
        this.token = json?.token;
        return this;
    }
}