import {useHistory, useLocation, useParams} from "react-router-dom";
import {MapContainer, TileLayer, Marker, useMap} from "react-leaflet";

import {Icon} from "leaflet";
import React, {useEffect, useState} from "react";
import "leaflet/dist/leaflet.css";
import "./tourenmap.css";
import markerPng from "./marker.png";
import lasterPng from "./Laster.png";
import {useToast} from "../../Components/Toast/ToastProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useAuth} from "../Auth/ProvideAuth.js";

export function TourenMap() {
    let auth = useAuth();
    const toast = useToast();
    const {tour} = useLocation().state ?? {tour: null};
    const history = useHistory();
    if (tour == null) {
        history.replace("/");
    }

    const posToMarker = ({DecimalX: x, DecimalY: y}) => [y ?? "", x ?? ""];

    const {startPosition: start, endPosition: end, lastPosition: last} = tour;


    const [startPosition, setStartPosition] = useState(posToMarker(start));
    const [endPosition, setEndPosition] = useState(posToMarker(end));
    const [lastPosition, setLastPosition] = useState(last ? posToMarker(last) : null);


    function ChangeView() {
        const map = useMap();
        map.fitBounds([startPosition, endPosition, lastPosition],{animate:false});
        map.zoomOut(0.5);
        return null;
    }


    if (startPosition && endPosition) {
        return (
            <>
                {!auth.user && (
                    <button
                        className="absolute px-8 py-0 text-white bg-blue-900 left-4 top-24 rounded-md hover:text-white hover:bg-blue-600"
                        onClick={() => history.goBack()}
                    >
                        <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                    </button>
                )}
                <div className="bg-white">
                    <MapContainer center={startPosition} className="map" zoom={13} zoomSnap={0.1} >
                        <ChangeView/>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                        <Marker
                            icon={
                                new Icon({
                                    iconUrl: markerPng,
                                    iconSize: [40, 40],
                                    iconAnchor: [20, 40],
                                })
                            }
                            position={startPosition}
                        />
                        {lastPosition && (
                            <Marker
                                icon={
                                    new Icon({
                                        iconUrl: lasterPng,
                                        iconSize: [40, 40]
                                    })
                                }
                                position={lastPosition}
                            />
                        )}
                        <Marker
                            icon={
                                new Icon({
                                    iconUrl: markerPng,
                                    iconSize: [40, 40],
                                    iconAnchor: [20, 40],
                                })
                            }
                            position={endPosition}
                        />
                    </MapContainer>
                </div>
            </>
        );
    }
}
