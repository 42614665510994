import React from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage, faWindowClose } from "@fortawesome/free-solid-svg-icons";

function ImageUploader(props) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
  });

  let Files = acceptedFiles.map(
    (file) =>
      handleImageFile(file) &&
      true && (
        <ul className="w-min flex space-x-3 my-6 bg-gray-50 p-2 rounded-md">
          <FontAwesomeIcon
            className="my-1"
            icon={faFileImage}
            style={{ fontSize: 22 }}
          />
          <li key={file.path}>{file.path}</li>
        </ul>
      )
  );

  async function handleImageFile(file) {
    const hashedFile = await convertFileToBase64(file);
    if (hashedFile instanceof Error) {
      console.log(hashedFile.message);
      return;
    }
    props.setImageFile(hashedFile);
  }

  function removeFile() {
    Files = [];
  }
  async function convertFileToBase64(file) {
    if (!file) return;
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      console.log("the file ist", file);
      fileReader.readAsDataURL(file);
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  return (
    <section className="flex-col container border-width-1 border-indigo-50">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <button className="flex items-center justify-center w-full px-4 py-3 text-gray-900 bg-gray-300 rounded-md focus:outline-none">
          Foto einfügen
        </button>
        {Files}
      </div>
      <aside></aside>
    </section>
  );
}

export default React.memo(ImageUploader);
