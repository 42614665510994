import React, {useEffect, useState} from "react";
import {useParams, useHistory, useLocation} from "react-router-dom";
import {getAuftragsListe} from "../../Api";
import Pagination from "../../Components/Pagination/Pagination";
import "./auftragpage.css";
import {debounce} from "lodash";

function Row({auftrag, standort}) {
    const history = useHistory();

    async function navigateToTourenPage() {
        history.push({
            pathname: `/tour/${auftrag.Nummer}`,
            state: {standort},
        });
    }

    return (
        auftrag && (
            <tr className="my-5">
                <td label="Datum" className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                        <div className="">
                            <div className="text-sm font-medium text-gray-900">
                                {new Date(auftrag.Termin).toLocaleDateString()}
                            </div>
                        </div>
                    </div>
                </td>
                <td label="Artikel" className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                        <div className="">
                            <div className="text-sm font-medium text-gray-900">
                                {auftrag.Artikel.Name}
                            </div>
                        </div>
                    </div>
                </td>

                <td
                    label="Status"
                    className="px-6 py-2 overflow-y-visible whitespace-nowrap"
                >
          <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
            <span
                aria-hidden
                className="absolute inset-0 bg-green-200 rounded-full opacity-50"
            ></span>
            <span className="relative text-xs">
              {auftrag.Status.toLowerCase()}
            </span>
          </span>
                </td>

                <td label="Menge" className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center min-w-full">
                        {auftrag.Menge} {auftrag.Artikel.EinheitKuerzel}
                    </div>
                </td>
                <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                    <button
                        onClick={navigateToTourenPage}
                        className="text-blue-600 hover:text-blue-900"
                    >
                        Touren
                    </button>
                </td>
            </tr>
        )
    );
}

function AuftragPage() {
    const [auftrag, setAuftrag] = useState({
        TotalPages: 1,
        PageNumber: 1,
        Elements: []
    });
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [fetchOptions, setFetchOptions] = useState({
        pageNumber: 1,
        pageSize: 7,
        Search: "",
        OrderBy: "Termin",
        OrderDirection: "DESC"
    });

    const {kundenId, standortId} = useParams();
    const history = useHistory();
    const {standort, kunde} = useLocation().state;

    const searchdebounced = debounce((value) => {
        setFetchOptions({...fetchOptions, Search: value, pageNumber: 1});
    }, 500)

    function handleSearch({target: {value}}) {
        setSearch(value);
        searchdebounced(value);
    }

    async function getAuftraege(pageNumber = 1) {
        setIsLoading(true);
        const auftraege = await getAuftragsListe(kundenId, standortId, {...fetchOptions});
        setAuftrag(auftraege);
        setIsLoading(false);
    }

    useEffect(() => {
        getAuftraege();
    }, [fetchOptions]);

    useEffect(() => {
        setTotalPages(auftrag.TotalPages);
        setCurrentPage(auftrag.PageNumber);
    }, [auftrag])

    function handleHeaderClick(column) {
        const sort = {OrderBy: column, OrderDirection: "ASC"};
        if (fetchOptions.OrderBy == sort.OrderBy) {
            sort.OrderDirection = fetchOptions.OrderDirection == "ASC" ? "DESC" : "ASC";
        }
        setFetchOptions({...fetchOptions, ...sort});
    }

    function setPage(pageNumber) {
        setFetchOptions({...fetchOptions, pageNumber});
    }


    return (
        <div className="">
            <h3 className="px-4 my-8 text-3xl font-medium text-gray-700">
                Auftragsübersicht für {standort?.Kuerzel ?? JSON.stringify(standort)}
            </h3>
            <button
                onClick={() => history.push("/")}
                className="px-8 py-1 my-4 text-white bg-blue-900 rounded-md hover:text-white hover:bg-blue-600"
            >
                fertig
            </button>
            {isLoading || auftrag?.Elements.length==0
                ? <div className="relative h-96">
                      <span className="absolute inset-0 flex items-center justify-center">
                        {isLoading ? "Laden...":"keine Aufträge gefunden"}
                      </span>
                </div>
                : <div className="flex flex-col bg-white">
                    <div className="overflow-x-auto">
                        <div className="">
                            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <input
                                    type="text"
                                    placeholder="Suchen"
                                    onChange={handleSearch}
                                    value={search}
                                    className="relative block w-48 px-5 py-2 mx-3 mt-3 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                />
                                <table className="min-w-full min-h-full p-6 divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            className="px-6 py-3 tracking-wider text-left text-gray-500">
                                            <button className={"uppercase text-xs font-medium"} onClick={() => handleHeaderClick("Termin")}>Entladedatum</button>
                                        </th>
                                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                            Artikelname
                                        </th>
                                        <th
                                            className="px-6 py-3 tracking-wider text-left text-gray-500">
                                            <button className={"uppercase text-xs font-medium"} onClick={() => handleHeaderClick("Status")}>Auftragsstatus</button>
                                        </th>
                                        <th
                                            className="px-6 py-3 tracking-wider text-left text-gray-500">
                                            <button className={"uppercase text-xs font-medium"} onClick={() => handleHeaderClick("Menge")}>Menge</button>
                                        </th>
                                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"></th>
                                    </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                    {auftrag?.Elements?.map(
                                        (a, key) =>
                                            a && <Row key={key} standort={standort.Kuerzel} auftrag={a}/>
                                    )}
                                    </tbody>
                                </table>
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    handlePageChange={setPage}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default AuftragPage;
