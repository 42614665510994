import React from "react";
import { useAuth } from "./ProvideAuth";
import { Route, Redirect } from "react-router-dom";
import { roles } from "./Auth";

function AdminRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user && auth.user.Role === roles.admin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: auth.user ? "/" : "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default AdminRoute;
