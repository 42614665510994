import React from "react";
import logo from "../../BHT_logo.png";
import "./Header.css";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../Auth/ProvideAuth";
import Nav from "../Nav/Nav";

function Header() {
  return (
    <header className="flex p-4 py-1 border-b-2 border-gray-100 shadow-lg App-header">
      <Link to="/" key="root">
        <img
          src={logo}
          className="App-logo"
          alt="appkipper.de - Schüttgut einfach online"
          width={370}
          height={97}
        />
      </Link>
      <Nav />
    </header>
  );
}

export default Header;
