import auth from "./Components/Auth/Auth";
import {roles} from "./Components/Auth/Auth";

// const siteUrl = process.env.REACT_APP_SITE_URL;
const apiUrl = process.env.REACT_APP_API_URL;

let cache = {};

export function emptyCache() {
    cache = {};
}

const error = async (response) => {
    console.error(response);
    // console.error((await response.json()).Message);
};

const checkResponse = async (response) => {
    if (!response.ok) {
        await error();
    }
};

const getUrl = (path, qs = {}) => {
    const url = new URL(path, apiUrl);
    for (let key in qs) {
        url.searchParams.append(key, qs[key]);
    }
    return url.toString();
};

export const getTimeRulesSettings = async () => {
    return await doFetch("api/Settings/Timerules");
};

export const setTimeRulesSettings = async (rules) => {
    const url = new URL("api/Settings/Timerules", apiUrl);
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", auth.getToken());
    const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify({Rules: rules}),
    });
    return {...(await response.json()), ok: response.ok};
};

export const submitOrderData = async (formData) => {
    const url = new URL("api/Order/OrderData", apiUrl);
    if (auth.getToken()) {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", auth.getToken());
        const response = await fetch(url, {
            method: "POST",
            headers,
            body: JSON.stringify(formData),
        });
        checkResponse(response);
        return {...(await response.json()), ok: response.ok};
    }
};

export const getOrderData = async (kundenId, standortId) => {
    return doFetch(`api/Order/OrderData/${kundenId}/${standortId}`);
};

export const updateUser = async (formData) => {
    const url = new URL("api/Account/UpdateAccount", apiUrl);
    const headers = new Headers();
    headers.append("Authorization", auth.getToken());
    headers.append("Content-Type", "application/json");
    const res = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify({
            UserID: formData.UserID,
            Benutzername: formData.Benutzername,
            Role: formData.Role ?? roles.user,
            Kundennummer: formData.Kundennummer,
            Standorte: formData.FreigegebeneStandorte,
        }),
    });
    return res;
};
export const updatePassword = async (formData) => {
    const url = new URL("api/Account/ChangePassword", apiUrl);
    const headers = new Headers();
    headers.append("Authorization", auth.getToken());
    headers.append("Content-Type", "application/json");
    const res = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify({
            OldPassword: formData.oldPassword,
            NewPassword: formData.newPassword,
            ConfirmPassword: formData.confirmPassword,
        }),
    });
    return res;
};

const doFetch = async (path, {noCache = false, ...qs} = {noCache: false}, withToken = true) => {
    const url = getUrl(path, qs);
    if (!noCache && cache[url] && cache[url].ok) return cache[url];
    const headers = new Headers();
    const timeout = 20000;
    const controller = new AbortController();
    const timer = setTimeout(()=>controller.abort('timeout'),timeout);
    headers.append("Authorization", auth.getToken());
    const response = await fetch(url, {
        headers: headers,
        signal:controller.signal
    });
    checkResponse(response);
    const data = await response.json();
    clearTimeout(timer);
    cache[url] = {...data, ok: response.ok};
    return cache[url];
};

export const setPassword = async (userId, newPassword) => {
    const url = new URL("api/Account/SetPassword", apiUrl);
    const headers = new Headers();
    const body = JSON.stringify({
        UserID: userId,
        NewPassword: newPassword,
        ConfirmPassword: newPassword,
    });
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", auth.getToken());

    const response = await fetch(url.toString(), {
        method: "POST",
        headers,
        body,
    });
    checkResponse(response);
    return response.ok;
};
export const getUserAccounts = async (fetchOptions) => {
    return await doFetch("api/Account/ManageAccounts", fetchOptions);
};
export const getAuftragsListe = async (kundenId, standortId, qs) => {
    return {
        ...(await doFetch(`api/Opheo/Auftragsliste/${kundenId}/${standortId}`, {
            ...qs
        })),
    };
};
export const getTouren = async (auftragId,qs) => {
    const url = `api/Opheo/Touren/${auftragId}`;
    return await doFetch(url,qs);
};

export const getTourenAnonym = async (auftragId, PLZ,qs={}) => {
    const url = `api/Opheo/TourenAnnonym/${auftragId}`;
    return await doFetch(url, {PLZ,...qs});
};

export const newRegistration = async (formData) => {
    const url = new URL("api/Account/RequestRegister", apiUrl);
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const body = JSON.stringify({
        Telefon: formData.telefon,
        Email: formData.email,
        Firmenname: formData.company,
        Vorname: formData.firstname,
        Nachname: formData.lastname,
        Bemerkung: formData.annotation
    });
    return await fetch(url, {
        method: "POST",
        headers,
        body,
    });
};

export const removeAccount = async (UserID) =>{
    const url = new URL("api/Account/RemoveAccount", apiUrl);
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", auth.getToken());
    const body = JSON.stringify({
        UserID
    });
    return await fetch(url, {
        method: "POST",
        headers,
        body,
    });
}

export const doRegister = async (formData, role = roles.user) => {
    const url = new URL("api/Account/Register", apiUrl);
    const body = JSON.stringify({
        Username: formData.username,
        Password: formData.password,
        OpheoNummer: formData.kundennummer,
        Role: role,
    });
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", auth.getToken());

    const response = await fetch(url.toString(), {
        method: "POST",
        headers,
        body,
    });
    checkResponse(response);
    return response.ok;
};
export const doLogin = async (username, password) => {
    const url = new URL("token", apiUrl);
    const params = {
        username: username,
        password: password,
        grant_type: "password",
    };

    const body = [];
    for (let k in params) {
        body.push(k + "=" + encodeURIComponent(params[k]));
    }
    const headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const response = await fetch(url.toString(), {
        method: "POST",
        headers,
        body: body.join("&"),
    });
    checkResponse(response);
    return {
        ok: response.ok,
        status: response.status,
        ...(await response.json()),
    };
};

window.doLogin = doLogin;

export const getKunden = async (qs) => {
    return await doFetch("api/Opheo/Kunden", qs);
};

export const getKunde = async (userid, qs) => {
    return await doFetch("api/Opheo/Kunden/"+userid, qs);
}