import React, { useState } from "react";
import auth from "../../Components/Auth/Auth";

export default function TestPage() {
  const [text, setText] = useState("default test text");
  let postAnnonym = async (e) => {
    const url = new URL("api/Test/DataAnnonym", process.env.REACT_APP_API_URL);
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({ text: text }),
    });
    const json = await response.json();
    setText(json.text);
  };
  let post = async (e) => {
    if (auth.getToken()) {
      const url = new URL("api/Test/Data", process.env.REACT_APP_API_URL);
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", auth.getToken());
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify({ text: text }),
      });
      const json = await response.json();
      setText(json.text);
    } else {
      alert("erst einloggen");
    }
  };

  return (
    <>
      <input
        type="text"
        name=""
        onChange={({ target: { value } }) => setText(value)}
        value={text}
        className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
      />
      <button
        type="submit"
        onClick={postAnnonym}
        className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-900 border border-transparent group rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        PostTestAnnonym
      </button>
      <button
        type="submit"
        onClick={post}
        className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-900 border border-transparent group rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        PostTest
      </button>
    </>
  );
}

