import React, { useState } from "react";
import { useAuth } from "../../Components/Auth/ProvideAuth";
import { Transition } from "@headlessui/react";
import { useHistory, useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";

function LogInPage() {
  let history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [hoverEffect, setHoverEffect] = useState(false);
  const [error, setError] = useState(false);

  let location = useLocation();
  let auth = useAuth();

  let { from } = location.state || { from: { pathname: "/" } };

  let login = (e) => {
    e.preventDefault();
    setisLoading(true);
    auth.signin(username, password, (user) => {
      if (user) {
        setIsOpen(false);
        setisLoading(false);
        history.push(from);
      } else {
        // toast.push("Nutzername oder Passwort nicht richtig", "Error", 4000);
        setError("Fehler bei der Anmeldung");
        setisLoading(false);
      }
    });
  };

  return (
    <Transition
      show={isOpen}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {error && (
        <div className="absolute flex items-center justify-center sm:-px-48">
          <div className="flex flex-col items-center px-6 py-3 text-white bg-red-600 border-t border-red-600 shadow-2xl sm:-mx-11 sm:rounded-lg sm:flex-row sm:border">
            <div>Anmeldung fehlgeschlagen...</div>
            <div className="flex mt-2 sm:mt-0 sm:ml-4">
              <button
                onClick={() => setError("")}
                className="px-3 py-2 hover:bg-red-700 transition ease-in-out duration-300"
              >
                {" "}
                ausblenden
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="items-center self-center justify-center px-4 py-8 my-16 shadow-md bg-gray-50 lg:px-8 md-8">
        <div className="w-full max-w-md py-2 mb-6">
          <div>
            <h2 className="mt-1 text-3xl font-extrabold text-center text-gray-900">
              Melde dich an
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={login}>
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="username" className="sr-only">
                  Benutzername
                </label>
                <input
                  id="username"
                  onChange={({ target: { value } }) => setUsername(value)}
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Benutzername"
                />
              </div>
              <div>
                <label className="sr-only">Passwort</label>
                <input
                  id="password"
                  name="password"
                  onChange={({ target: { value } }) => setPassword(value)}
                  type="password"
                  value={password}
                  autoComplete={"current-password"}
                  required
                  className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Passwort"
                />
              </div>
            </div>

            <div className="flex items-center justify-between ">
              <div className="flex items-center ">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
                <label className="block ml-2 text-sm text-gray-900">
                  Passwort speichern
                </label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                onMouseEnter={() => setHoverEffect(true)}
                onAnimationEnd={() => setHoverEffect(false)}
                className={` ${
                  hoverEffect && "animate-wiggle"
                } hover:wiggle hover:animate-wiggle relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-900 group rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    className="w-5 h-5 text-blue-500 group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" />
                  </svg>
                </span>
                {isLoading ? (
                  <ClipLoader size="20" color="#ffffff" />
                ) : (
                  "Einloggen"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Transition>
  );
}

export default LogInPage;
